// ThemeContext.js
import React, { createContext, useContext} from 'react';
import { ConfigProvider, theme } from 'antd';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  



  // Determine the primary and secondary colors based on the theme mode
  const primaryColor  =  getComputedStyle(document.documentElement).getPropertyValue('--color-primary').trim();

  const secondaryColor= getComputedStyle(document.documentElement).getPropertyValue('--color-secondary').trim();

  // Define the Ant Design theme configuration, including dynamic primary and secondary colors
  const antdTheme = {
    algorithm:  theme.defaultAlgorithm,
    token: {
      colorPrimary: primaryColor,
      colorSecondary: secondaryColor, // Add the secondary color here
    },
  };

  return (
    <ThemeContext.Provider value={{ colorMode: "light" }}>
      <ConfigProvider theme={antdTheme}>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};
