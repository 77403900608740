import React from "react";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import con from "../config.json";

const LandingPage = () => {
  return (
    <AppLayoutLogin>
      <div className="main-section dir-col  max-width10 side-padding2">
       
          <h2 className="large-text b-margin2">Privacy policy</h2>
          <h6 className="b-margin2 t-margin2">
            We specialize in providing
            screening services for politically exposed persons (PEPs) and
            sanctions for the clients we serve. Your privacy and the security of
            your data are of utmost importance to us. This notice outlines our
            practices and commitments in handling personal information to ensure
            compliance with regulatory requirements and protect your rights. If
            you have any questions or concerns about our privacy practices,
            please contact us at {con.email.support} .
          </h6>
          <h3 className="b-margin2 t-margin4">Data Collection and Usage</h3>
          <h4 className="b-margin2">Visitors to checklynx.com landing page</h4>
          <p className="b-margin1">
            Our landing page is designed to provide you with information about
            our services. We collect limited data on how you use our site to
            help improve your user experience.
          </p>
          <h5 className="b-margin1">What We Collect:</h5>
          <p className="b-margin1">
            Data on how you use the site: This includes the pages you visit, the
            device and browser you use (including browser version, time zone,
            operating system, etc.), the duration of your visits, page views,
            the frequency of your visits, and how you navigate through the site.
          </p>
          <p className="b-margin1">
            Data that identifies you: Your IP address and any unique identifiers
            tied to cookies, if used.
          </p>
          <h5 className="b-margin1">What We Do with Your Data:</h5>
          <p className="b-margin1">
            Site Optimization: We analyze aggregated data to update our site’s
            content and layout to improve relevance and accessibility for
            visitors. This helps us better understand visitor behavior and
            tailor our services accordingly.
          </p>
          <h5 className="b-margin1">Our Basis for Data Processing:</h5>
          <p className="b-margin1">
            Legitimate Interests: We process this data based on our legitimate
            interests in enhancing our website and marketing our services more
            effectively.
          </p>
          <h5 className="b-margin1">Retention Period:</h5>
          <p className="b-margin1">
            We retain the data collected via cookies as long as necessary to
            fulfill the purposes outlined in this privacy policy. Data holding
            periods are determined by cookie expiry times.
          </p>
          <h5 className="b-margin1">Recipients of Data:</h5>
          <p className="b-margin1">
            <li className="b-margin1 t-margin1">
              <strong>Internal Recipients: </strong>
              Personal data collected through our landing page is accessible to
              our team.{" "}
            </li>
            <li className="b-margin1 t-margin1">
              <strong>External Recipients:</strong> Data about how you interact
              with our site is shared with analytics providers to help us
              understand user behavior, improve our service offerings, and
              enhance user experience.{" "}
            </li>
          </p>
          <h5 className="b-margin1">
            Use of Google Analytics and Cookie Management{" "}
          </h5>
          <p className="b-margin1">
            We use Google Analytics to collect data on visitor interactions with
            our site. This tool helps us measure site traffic, understand
            visitor behaviors, and improve our services. Google Analytics
            collects information such as how often users visit our site, what
            pages they visit, and what other sites they used prior to visiting.
            We only collect this data if you consent to cookies.
          </p>
          <p className="b-margin1">
            To manage your cookie preferences and consent, we use Cookiebot, a
            cookie consent management platform. Cookiebot enables you to accept
            or reject cookies categorically and provides detailed control over
            your privacy settings.
          </p>
          <h4 className="b-margin2 t-margin2">Portal </h4>
          <h5 className="b-margin1 ">Local Storage Use: </h5>
          <p>
            We utilize local storage on our portal to enhance user experience
            and facilitate seamless interaction. The following data is stored
            locally on your device:
            <li className="b-margin1 t-margin1">
              <strong>User Preferences: </strong>Your preference for display
              mode (dark or bright mode) is stored to maintain consistency in
              user experience across sessions.
            </li>
            <li className="b-margin1 t-margin1">
              <strong>User Information: </strong>Essential user details such as
              username, user ID, and company name are stored locally to avoid
              the need for repeated login and to maintain session continuity.{" "}
            </li>
          </p>
          <h5 className="b-margin1 "> Types of Data Collected:</h5>
          <p>
            <li className="b-margin1 t-margin1">
              <strong>Mandatory Data:</strong>
              Email Address. Required to create your account, verify your
              identity, and facilitate communication regarding updates or issues
              with the service.
            </li>

            <li className="b-margin1 t-margin1">
              <strong>Optional Data:</strong>
              Name and Surname. These details are optional and can be provided
              to personalize your user experience and for identification
              purposes within our support services. Purpose of Data Collection:
              This information is collected to provide and manage access to our
              portal, ensure security of your account, and improve our customer
              support and service delivery.
            </li>
          </p>
          <h5 className="b-margin2 t-margin2">
            Purpose of Storing Information:{" "}
          </h5>
          <p>
            The stored data is used solely for the purpose of enhancing user
            experience within our portal. It enables us to personalize your
            interaction and maintain the state of your session across visits.
          </p>
          <h5 className="b-margin2 t-margin2">Data Security:</h5>
          <p>
            We take the security of your locally stored data seriously and have
            implemented measures to safeguard it against unauthorized access.
            However, it's important to note that local storage data remains
            within your device's control and is subject to your device's
            security settings.
          </p>
          <h5 className="b-margin2 t-margin2">User Control and Rights:</h5>
          <p>
            You have the right to manage or delete the locally stored data at
            any time through your browser settings. Upon logging out of our
            portal, we automatically clear this data from your device to protect
            your privacy.
          </p>
          <h3 className="b-margin2 t-margin4">Additional Considerations</h3>
          <h4>Changes to Our Privacy Policy</h4>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </p>
          <h4>Data Security: </h4>
          <p>
          We take the protection of your personal
          data seriously. We employ generally accepted administrative, physical,
          and technical safeguards designed to protect the confidentiality,
          integrity, and availability of your personal data. These measures
          include: 
          <li className="b-margin1 t-margin1">
          <strong>Administrative Safeguards: </strong>Policies and procedures designed
          to manage data security and ensure that our staff maintains data
          confidentiality and integrity. Physical Safeguards: Security measures
          to protect our data storage facilities and equipment from physical
          threats and hazards. </li>
          <li className="b-margin1 t-margin1">
          <strong>Technical Safeguards: </strong>Technologies and controls
          that support data security, such as encryption, firewalls, and secure
          software development practices. </li>
          While we strive to use commercially
          acceptable means to protect your personal data, no method of
          transmission over the Internet, or method of electronic storage, is
          100% secure. Therefore, while we strive to use commercially acceptable
          means to protect your personal data, we cannot guarantee its absolute
          security.
          </p>
       
      </div>

      <Footer />
    </AppLayoutLogin>
  );
};

export default LandingPage;
