import React from "react";
import e2esearch from "../images/e2esearch.webp";
import associates from "../images/associates.webp";
import identification from "../images/identification.webp";
import addresses from "../images/addresses.webp";
import { useInView } from "react-intersection-observer";
import {AnimatedCard} from "./Utils";
import { Carousel } from "antd";
import x from "../images/x.svg";

const DataExperience = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Adjust as needed
  });
  return (
    <div
      id="data-xperience"
      className="flex-no-wrap dir-col justify-center align-center main-section max-width12 "
    >
      <div className="flex header justify-center">
        <h2
          ref={ref}
          className={` display-heading dir-row flex ${inView ? "animate" : ""}`}
        >
          Data <img src={x} alt="X" /> pirience
        </h2>

        <h4 ref={ref} className={`  align-center ${inView ? "animate" : ""}`}>
          Checklynx transforms complex, raw data into a structured,
          easy-to-navigate format.
        </h4>

        <h4 ref={ref} className={`   ${inView ? "animate" : ""}`}>
        Reduce the number of records needing to be reviewed. 
        Our targets are deduplicated, all information by the sanction issuers are available on that target. 
          
        </h4>
      </div>

      <div className="flex-no-wrap xpirience-cards2 b-padding1 side-padding3-1">
        <FeaturesCards1></FeaturesCards1>

        <SimpleCarousel></SimpleCarousel>
      </div>

      <div className="grid-container-dex  b-padding2 side-padding3-1">
        <ImgExample></ImgExample>
        <FeaturesCards3> </FeaturesCards3>
      </div>
    </div>
  );
};

export default DataExperience;

export const ImgExample = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust the threshold as needed for when you want the animation to start
  });
  return (
    <div
      ref={ref}
      className={`grid-item max-width7 ${inView ? "animate" : ""}`}
    >
      <img src={e2esearch} alt="search example" className="img-responsive" />
    </div>
  );
};

export const SimpleCarousel = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust the threshold as needed for when you want the animation to start
  });
  return (
    <div
      ref={ref}
      className={`  card-feature focus-bg  t-padding2   ${
        inView ? "animate" : ""
      }`}
    >
      <div className="carousel-container  side-padding1 ">
        <div className="carousel ">
          <Carousel
            arrows
            dotPosition="bottom"
            infinite={false}
            autoplay={false}
            autoplaySpeed={6000}
          >
            <div className="flex card-feature side-padding2 b-padding2f">
              <div className="flex white justify-center side-padding2">
                <h6>ISO-compliant formats for countries and dates</h6>
                <img
                  src={addresses}
                  alt="iso compliant formats for countries and dates"
                  className="img-responsive max-width4"
                />
              </div>
            </div>
            <div className="flex card-feature side-padding2  ">
              <div className="flex white justify-center side-padding2 b-padding2f">
                <h6>Passports, reg. numbers, SWIFT, national IDs...</h6>

                <img
                  src={identification}
                  alt="X"
                  className="img-responsive max-width4"
                />
              </div>
            </div>

            <div className="flex card-feature side-padding2 b-padding2f ">
              <div className="flex white justify-center side-padding2">
                <h6>Categorizes human and business links</h6>
                <img
                  src={associates}
                  alt="X"
                  className="img-responsive max-width4"
                />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};



export const FeaturesCards1 = () => {
  return (
    <div className="xpirience-cards1 justify-space-between  dir-col ">
      <AnimatedCard bgClass="card-feature focus-bg focus-xpirience">
        <h3 className="no-margin">
          Dispare data into consistent structured formats
        </h3>
      </AnimatedCard>
      <AnimatedCard bgClass="card-feature focus-bg focus-xpirience">
        <h3 className="no-margin">
          Simplified: Less time decoding, more time acting
        </h3>
      </AnimatedCard>
    </div>
  );
};

export const FeaturesCards3 = () => {
  return (
    <div className="grid-container-dex-f  ">
      <AnimatedCard bgClass="card-feature grid-item-dex-f focus-bg focus-xpirience">
        <h3 className="no-margin">Faster learning curve</h3>

        
      </AnimatedCard>
      <AnimatedCard bgClass="card-feature grid-item-dex-f focus-bg focus-xpirience">
        <h3 >Contextual help</h3>

        <h5 className="no-margin">
            User guidance through all tasks.
        </h5>
      </AnimatedCard>

      <AnimatedCard bgClass="card-feature grid-item-dex-f focus-bg focus-xpirience">
        <h3>Responsive design</h3>

        <h5 className="no-margin">
          Optimized for all screen sizes and devices, in bright and dark modes.
        </h5>
      </AnimatedCard>
    </div>
  );
};
