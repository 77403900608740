import React from "react";

import AppLayoutLogin from "../components/AppLayoutLogin";

import Footer from "../components/Footer";
import con from "../config.json";

const LandingPage = () => {
  return (
    <AppLayoutLogin>
      <div className="main-section dir-col  max-width10 side-padding3">
        <h2 className="large-text b-margin2">Terms and conditions</h2>
        <h3 className="b-margin2 t-margin4">Introduction</h3>
        <p className="b-margin1">
          Checklynx hereafter referred to as "Service Provider," "we," "us," or
          "our," incorporated in Germany. Member of the IHK Berlin, membership
          number 107#2246621. We specialize in providing sanctions screening
          solutions that assist businesses in complying with international
          regulations.
        </p>
        <p className="b-margin1">
          By accessing and using our services ("Service"), you ("User" or
          "Client") agree to these Terms of Service and our Privacy Policy. If
          you do not accept any part of these terms, you are required not to use
          our services.
        </p>
        <p className="b-margin1">
          Our services are designed to support your compliance efforts with
          robust and effective sanctions screening tools, enhancing operational
          integrity across various sectors. Your agreement to these terms is
          confirmed by your use of our Service.
        </p>
        <p className="b-margin1">Effective: June 1st, 2024.</p>

        <h3 className="b-margin2 t-margin4">Modification of Terms</h3>
        <p className="b-margin1">
          We reserve the right to modify or amend these Terms of Service at any
          time without prior notice. To ensure that you are aware of any
          changes, we recommend reviewing these terms periodically. The most
          current version of the Terms of Service can always be found on our
          website at {con.urls.landing_url + "/terms"}.
        </p>
        <p className="b-margin1">
          By accessing and using our services ("Service"), you ("User" or
          "Client") agree to these Terms of Service and our Privacy Policy. If
          you do not accept any part of these terms, you are required not to use
          our services.
        </p>

        <h3 className="b-margin2 t-margin4">Privacy Policy</h3>
        <p className="b-margin1">
          We are committed to protecting your privacy and ensuring that your
          personal data is handled responsibly.
        </p>
        <p className="b-margin1">
          You can review the full details of our Privacy Policy at any time by
          visiting {con.urls.landing_url + "/privacy"}.
        </p>
        <p className="b-margin1">
          By using our Service, you acknowledge that you have read and
          understood our Privacy Policy and agree to its terms. If you do not
          agree with the Privacy Policy, you must cease using our services
          immediately.
        </p>

        <h3 className="b-margin2 t-margin4">Description of the Service</h3>

        <p className="b-margin1">
          Our service provides robust sanctions screening solutions through both
          an API and a web-based portal designed to support compliance with
          applicable sanctions
        </p>
        <ul>
          <li className="b-margin1 t-margin1">
            <strong>API Access: </strong>
            Our API allows for integration into your existing systems, enabling
            automated sanctions screening for individuals and entities.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Web Portal: </strong>
            Through the web portal, users can:
            <ul>
              <li>
                Conduct searches for targets (individuals or entities) and check
                against sanctions list and PEP.
              </li>

              <li> Manage user access within their organization. </li>

              <li> Generate, revoke, and manage API keys </li>
            </ul>
          </li>
        </ul>
        <h3 className="b-margin2 t-margin4">Limitations</h3>

        <p className="b-margin1">
          Clients are expressly prohibited from using any data obtained from our
          service for purposes that are not compliant with relevant legal and
          regulatory frameworks, including but not limited to:{" "}
        </p>
        <ul>
          <li className="b-margin1 t-margin1">
            <strong>Credit and Employment Screening: </strong>
            You may not use our data for screening individuals in contexts such
            as credit eligibility, issuing insurance, or employment, unless such
            use complies with applicable laws including the Fair Credit
            Reporting Act (FCRA) in the United States, the General Data
            Protection Regulation (GDPR) in the European Union, and other
            similar legislation in the jurisdictions of our users.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Consumer Reporting and Decision Making: </strong>
            Our data must not be used for making decisions about an individual's
            eligibility for benefits, services, or employment in a manner that
            would classify it as a “Consumer Report” under the FCRA or would
            require adherence to comparable legislation in other jurisdictions.
          </li>
        </ul>
        <p className="b-margin1">
          Failure to comply with these usage limitations constitutes a material
          breach of these Terms of Service and may result in the termination of
          your account, legal action, and other remedies available under law.
          Furthermore, the Client agrees to indemnify and hold harmless
          Checklynx from any claims, damages, or losses that may arise from the
          Client's non-compliance with applicable regulations.
        </p>

        <h3 className="b-margin2 t-margin4">Prohibited Uses</h3>

        <p className="b-margin1">
          You may use our Service for your designated know-your-customer (KYC)
          and compliance purposes ("the Purpose").
        </p>

        <p className="b-margin1">
          You agree not to reproduce, reverse-engineer, duplicate, copy, sell,
          resell, or in any way commercially exploit any portion of our Service,
          except as expressly agreed upon in writing by us.
        </p>

        <p className="b-margin1">
          The following activities are strictly prohibited:
        </p>

        <ul>
          <li className="b-margin1 t-margin1">
            <strong>Unlawful Activities:</strong> You may not use our Service:
            <ul>
              <li>
                For any purpose that is illegal or unauthorized by applicable
                laws and regulations.
              </li>
              <li>
                To engage in, solicit, or encourage any activity that
                constitutes a breach of any law, regulation, or ordinance.
              </li>
              <li>
                To infringe upon or violate our intellectual property rights or
                those of any third party.
              </li>
            </ul>
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Abusive Conduct:</strong>
            <ul>
              <li>
                To harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate against anyone on the basis of
                gender, sexual orientation, religion, ethnicity, race, age,
                national origin, or disability.
              </li>
              <li>To submit false, misleading, or deceptive information.</li>
            </ul>
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Malicious Activities:</strong>
            <ul>
              <li>
                To use any robot, spider, scraper, or other automated means to
                access our Service for any purpose without our express written
                permission.
              </li>
            </ul>
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Data Misuse:</strong>
            <ul>
              <li>
                To use, disclose, or distribute any data obtained from our
                Service, including information about third parties, without
                their express consent or in violation of applicable law or our
                data use agreements.
              </li>
              <li>
                To use data obtained from our Service for any purpose outside of
                the designated compliance and KYC processes, such as marketing
                or data brokering.
              </li>
            </ul>
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Security Breach:</strong>
            <ul>
              <li>
                To attempt to bypass or break our security mechanisms or to use
                the Service for any purpose that could damage, disable,
                overburden, or impair any aspect of any of our services or the
                technology or equipment supporting them.
              </li>
            </ul>
          </li>
        </ul>

        <p className="b-margin1">
          We reserve the right to terminate your access to our Service and to
          take legal action if you violate any of these prohibited uses.
        </p>

        <h3 className="b-margin2 t-margin4">Account Registration and Access</h3>

        <p className="b-margin1">
          To utilize our service, a company must register for an account through
          our website. The registration process involves the following steps:
        </p>

        <ul>
          <li className="b-margin1 t-margin1">
            <strong>AWS Marketplace customers:</strong> For customers who access
            our service through the AWS Marketplace, the registration process
            begins on the AWS Marketplace platform. Customers must initiate
            registration there and then finalize it on our website. Finalization
            requires entering the AWS customer ID provided during the initial
            step on the AWS Marketplace. This ensures seamless integration and
            access provisioning.
          </li>

          <li className="b-margin1 t-margin1">
            <strong>Company Information Submission:</strong> Provide company
            details including, but not limited to, the company name, address,
            and contact information.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Email Verification:</strong> Upon registration, we will send
            an activation code to the company's registered email address. The
            account will be activated once the provided code is entered on our
            platform, confirming the email address's validity.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Account Configuration:</strong> Post-verification, companies
            can configure their account settings, add or manage user access, and
            specify their service preferences.
          </li>
        </ul>

        <p className="b-margin1">
          As a user of our service, you are responsible for maintaining the
          confidentiality and security of your account information. Specific
          responsibilities include:
        </p>

        <ul>
          <li className="b-margin1 t-margin1">
            <strong>Accuracy of Information:</strong> Ensure that all account
            registration details are accurate, complete, and kept up-to-date.
            This is crucial for effective service delivery and compliance with
            regulatory requirements.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Confidentiality of Credentials:</strong> Safeguard your
            account credentials, including passwords and user names. Sharing
            these details with unauthorized persons is strictly prohibited.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Security Practices:</strong> Implement adequate security
            measures to prevent unauthorized access to your account. This
            includes using strong passwords and regularly updating them.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Access Management:</strong> Monitor and control access to
            your account. You are responsible for all activities that occur
            under your account and must immediately report any unauthorized use
            of your account or your API-keys to us.
          </li>
        </ul>

        <h3 className="b-margin2 t-margin4">Fees and Payment</h3>

        <p className="b-margin1">
          We offer a structured pricing model to accommodate the diverse needs
          of our users. Below you will find details on our subscription plans,
          payment terms, and conditions related to tiered services.
        </p>

        <ul>
          <li className="b-margin1 t-margin1">
            99 Euros fee per month with 100 searches per month included.
          </li>
          <li className="b-margin1 t-margin1">
            101 to 1,000 searches: €0.10 per search.
          </li>
          <li className="b-margin1 t-margin1">
            1,001 to 5,000 searches: €0.08 per search.
          </li>
          <li className="b-margin1 t-margin1">
            5,001 to 10,000 searches: €0.05 per search.
          </li>
          <li className="b-margin1 t-margin1">
            Over 10,001 searches: €0.03 per search.
          </li>
        </ul>
        <p className="b-margin1">
          Subscriptions grant access to all platform features and may be
          cancelled at any time at the subscriber's discretion. Notwithstanding
          the foregoing, subscribers are hereby informed that the monthly
          subscription fee of 99 Euros is fully non-refundable. Should a
          subscriber elect to terminate their subscription at any point during a
          billing cycle, please be advised that there shall be no proration or
          refund of the monthly fee already paid, irrespective of the time
          remaining in the subscription period.
        </p>
        <h4 className="b-margin2">Price Adjustments</h4>

        <p className="b-margin1">
          We reserve the right to modify our subscription fees or pricing
          structure at any time. Such adjustments will be reflected on our
          platform and, when possible, we will provide you with advance notice
          of these changes. Notice of any such changes will be communicated to
          you at least 30 days prior to the effective date of the new pricing by
          email or through a prominent notice on our website and/or through our
          service platform.
        </p>

        <p className="b-margin1">
          Upon receiving notice of a price increase, you will have the option to
          continue your subscription under the new pricing model or cancel your
          subscription before the changes take effect. Continued use of our
          service after the effective date of any price change will constitute
          your acceptance of the adjusted pricing terms.
        </p>

        <h3 className="b-margin2 t-margin4">Payment Terms</h3>

        <p className="b-margin1">
          All billing for our services is conducted through the Amazon
          Marketplace. Payment is required at the start of each billing cycle.
        </p>

        <p className="b-margin1">
          Upon registration, you will be directed to complete your payment setup
          via Amazon Marketplace, which will handle all aspects of billing and
          payment processing according to their terms and conditions.
        </p>

        <p className="b-margin1">
          Invoices are issued at the beginning of each payment period and are
          automatically handled through the Amazon Marketplace. Please ensure
          your payment information remains current in your Amazon account to
          avoid any interruption in service.
        </p>

        <p className="b-margin1">
          Failure to process payment successfully may result in a suspension or
          termination of services. Please address all billing inquiries directly
          through your Amazon Marketplace account.
        </p>
        <h3 className="b-margin2 t-margin4">
          Disclaimer and Limitation of Liability
        </h3>

        <p className="b-margin1">
          Except as expressly stated in this Agreement, the Client assumes all
          responsibility for results obtained from the use of the Services and
          the Site, including any adverse outcomes arising from misuse or
          misunderstanding. The Company disclaims all implied warranties to the
          fullest extent permitted by law.
        </p>

        <ul>
          <li className="b-margin1 t-margin1">
            The Company's liability for negligence, misrepresentation, or other
            legal claims is strictly limited to the 50% of the amount paid by
            the Client within the contract year.
          </li>
          <li className="b-margin1 t-margin1">
            We do not guarantee that the Services will be uninterrupted or
            error-free; nor do we make any warranty as to the results that may
            be obtained from use of the Services.
          </li>
          <li className="b-margin1 t-margin1">
            Notwithstanding the foregoing, nothing in this Agreement excludes or
            limits the Company's liability for death or personal injury caused
            by its negligence, fraud, or any other liability which cannot be
            lawfully excluded or limited.
          </li>
          <li className="b-margin1 t-margin1">
            The Company shall not be liable for indirect, special, or
            consequential damages including but not limited to loss of profits,
            business, anticipated savings, or data.
          </li>
        </ul>

        <h4 className="b-margin2">Service and Data Warranties</h4>

        <p className="b-margin1">
          The Company makes no guarantees that the services or data provided
          will be free from interruptions, or that the data ("Service Data")
          will always be accurate, up-to-date, complete, reliable, or fit for
          any specific purpose.
        </p>

        <ul>
          <li className="b-margin1 t-margin1">
            <strong>Service Interruptions:</strong> The Company does not warrant
            that the supply of the data and services or the use of our software
            will be uninterrupted. Clients acknowledge that technical issues may
            cause temporary disruptions.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Data Accuracy and Completeness:</strong> The accuracy,
            timeliness, and completeness of the Service Data are not guaranteed.
            The Service Data may not always reflect the most current data
            available.
          </li>
          <li className="b-margin1 t-margin1">
            <strong>Suitability for Use:</strong> The Company does not assert
            that the Service Data has been tested for use or is suitable for the
            Client's intended purposes. Clients are responsible for verifying
            that the data meets their needs.
          </li>
        </ul>
        <p className="b-margin1">
          Our Service may include or offer third-party content and provide links
          to third-party websites that are not affiliated with or controlled by
          us. We do not review, verify, endorse, or take responsibility for
          these external sources. We are not liable for the accuracy, legality,
          or content of any external sites or for that of subsequent links.
          Interaction with any linked website is subject to the terms and
          conditions and privacy policies of that website.
        </p>

        <p className="b-margin1">
          Clients use all Service Data and software at their own risk. The
          Company shall not be liable for any decisions made based on the use of
          the Service Data or interruptions in service.
        </p>
        <h3 className="b-margin2 t-margin4">Governing Law</h3>

        <p className="b-margin1">
          These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          laws of Germany, without regard to its conflict of law principles.
        </p>

        <p className="b-margin1">
          You agree that any legal action or proceeding between you and us
          related to these Terms of Service will be brought exclusively in a
          competent court located in Germany, and you agree to submit to the
          personal and exclusive jurisdiction and venue of these courts.
        </p>
      </div>

      <Footer />
    </AppLayoutLogin>
  );
};

export default LandingPage;
