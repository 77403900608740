//Cards.jsx
import React from 'react';


import { useInView } from 'react-intersection-observer';

export const ProductCard = ({ link, bgClass, iconSVG, heading, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.01,  // Adjust the threshold as needed for when you want the animation to start

  });
  return (
   
      <div onClick={() => {
        const section = document.getElementById(link);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }}>
    <div
    ref={ref} 
    className={`card bg-dark-selection align-center  ${inView ? 'animate' : ''}`}
    >
       
      <h3 className="text-dark no-margin">{heading}</h3>
      <h6 className="text-dark no-margin">{text}</h6>
      
      </div>
      </div>
   
  );
};





 export const ColoredCards = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.2, // Adjust as needed
  });
    return (
     
      <div className=" gradient_bg " > 
      <div className="main-section dir-col flex-no-wrap  max-width16" > 

      <h2 ref={ref} className={`header-narrow b-padding2  white ${inView ? 'animate' : ''}`}>
        Explore how our platform can streamline your compliance
      </h2>
        <div className="product-cards side-padding2">
        <div className="product-cards">
      
          <ProductCard 
            link="data-xperience"
            heading="Data X perience"
            text="Structured and enriched for optimal screening performance"
          />
          <ProductCard 
            link="search-experience"
            heading="Elevate Your Search Experience"
            text="Crafted to deliver relevant results and minimize false alarms"
          />
           </div>
           <div className="product-cards">
           <ProductCard 
            link="databases"
            heading="Global Sanctions & PEP Data"
            text="Hundreds of global and national sources continuously updated"
          />
         <ProductCard 
            link="integration"
            heading="Seamless Integration API"
            text="Built with developers in mind, designed for effortless integration"
          />
        </div>
        </div>
       
      </div>
      </div>
    );
  };
  
  