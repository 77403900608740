//landingPage
import React from "react";

import AppLayoutLogin from "../components/AppLayoutLogin";
import HeroSection from "../components/HeroSection";
import Databases from "../components/Databases";
import Integration from "../components/Integration";
import DataExpirience from "../components/DataExpirience";
import SearchExperience from "../components/SearchExperience";
import Pricing from "../components/Pricing";
import {ColoredCards} from "../components/Cards";
import BackgroundSVG from '../images/background.svg';
import Footer from "../components/Footer";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const LandingPage = ({ scrollTo }) => {
  const location = useLocation();

  useEffect(() => {
    if (scrollTo === 'pricing' || location.pathname === '/pricing') {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView();
      }
    }
  }, [scrollTo, location.pathname]);



  return (
    <AppLayoutLogin>
      
      <div style={contentStyle} >
        <HeroSection />
      
        <ColoredCards />
        </div>
        
      <DataExpirience />
     
      
      
      <SearchExperience/>

      <Databases />
      
      <Integration/>
      <Pricing/>
      
      <Footer
        showContactCard={true}
      />
      

     
    </AppLayoutLogin>
  );
};

export default LandingPage;
const contentStyle = {
  flexDirection: "column",
  display: "flex",
  width: "100%",
  backgroundImage: `url(${BackgroundSVG})`
  }
