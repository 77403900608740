import React from "react";
import { Button } from "antd";
import con from "../config.json";

import desktop_first_col from "../images/desktop_first_col.webp";
import stripe_logo from "../images/stripe.svg";
import aws_logo from "../images/aws.svg";
import { LogoCard } from "./Utils";
import { useInView } from "react-intersection-observer";

const HeroSection = () => {
 
  return (
    <div className="main-section  align-center  text-center ">
      <div className="align-flex reverse max-width14 ">
        <div className="align-center dir-col text-center max-width5 side-padding1">
          <h1 className="display-heading slide-in-down3">Sanctions and PEP Screening</h1>

          <h2 className="display_subheader slide-in-down3">
            Protect your business with our efficient screening solutions,
            available through both API and web portal.
          </h2>

          <div className="buttons-row slide-in-down2">
            <a
              href={con.urls.checklynx_app_sign_up}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className="w240 extra-height1 text-button-big"
                type="primary"
                size="large"
              >
                Try 30 days Free
              </Button>
            </a>

            <a
              href={con.calendly.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className="w240 extra-height1 text-button-big bg-white"
                type="primary"
                ghost={true}
                size="large"
              >
                Contact an expert
              </Button>
            </a>
          </div>
          <h6 className="side-padding2 no-margin slide-in-down3">Find us in</h6>
          <div className="buttons-row slide-in-down1">
            <LogoCard
              bgClass="card-logo"
              externalLink={con.urls.stripe_marketplace_product}
              iconSVG={stripe_logo}
            ></LogoCard>

            <LogoCard
              bgClass="card-logo "
              externalLink={con.urls.aws_marketplace_product}
              iconSVG={aws_logo}
            ></LogoCard>
          </div>
        </div>
        <div
          className={`max-width6 align-center side-padding2 `}>
      
          <img
            src={desktop_first_col}
            alt="Search Desktop"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
