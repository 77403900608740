import React from "react";
import { Button } from "antd";
import con from "../config.json";

const Footer = ({ showContactCard }) => {
  return (
    <div className="align-center main-section-no-padding dir-col">
      {showContactCard && <ContactCard />}
      <footer className={`footer ${showContactCard ? 'extra-top-padding' : ''}`}>

        <div className="footer-container">
          <div className="footer-section">
            <h5>Company</h5>
            <ul>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>

              <li>


                <a href="/contact">Contact Us</a>
                
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h5>Resources</h5>
            <ul>
              <li>
                <a
                  href={con.urls.checklynx_app}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Checklynx App
                </a>
              </li>
              <li>
                <a
                  href={con.urls.dev_portal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Developer Portal
                </a>
              </li>
              <li>
                <a
                  href={con.urls.aws_marketplace_product}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AWS marketplace
                </a>
              </li>
              <li>
                <a
                  href={con.urls.stripe_marketplace_product}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stripe marketplace
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
        <h6> @2024 Checklynx. All rights reserved.</h6>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

const ContactCard = () => {
  return (
    <div className="contact-card">
      <div className="text-center b-padding2 t-padding2 side-padding2">
        <h3>Start your journey</h3>
        <h5>Don’t risk your AML compliance</h5>
      </div>
      <div className="text-center buttons-grid b-padding2 t-padding2 side-padding2">
      <a href={con.calendly.url} target="_blank" rel="noopener noreferrer">
        <Button className="w240 extra-height1 text-button-big" size="large" type="default" ghost={true}>
          Contact an expert
        </Button>
      </a>
      <a href={con.urls.checklynx_app_sign_up} target="_blank" rel="noopener noreferrer">
        <Button className="w240 extra-height1 text-button-big bg-white" size="large" type="primary" ghost={true} >
          Try 30 days free
        </Button>
      </a>
      </div>
     
    </div>
  );
};
