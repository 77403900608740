import React from "react";
import code_thinking from "../images/code_thinking.svg";
import api_example from "../images/api_example.webp";
import { useInView } from "react-intersection-observer";
import { AnimatedCard } from "./Utils";
import { Button } from "antd";
import con from "../config.json";

const SearchExperience = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Adjust as needed
  });
  return (
    <div id="integration" className=" main-section  bg-dark ">
      <div className=" dir-col max-width14">
        <h2
          ref={ref}
          className={` display-heading side-padding1 b-padding1 text-center white ${
            inView ? "animate" : ""
          }`}
        >
          Seamless Integration
        </h2>

        <div className="grid-container side-padding1">
          
          <FeaturesCards1></FeaturesCards1>
          <ImgExample> </ImgExample>
        </div>
      </div>
    </div>
  );
};

export default SearchExperience;

export const ImgExample = () => {
  return (
    <div className=" max-width4">
      <img
        src={api_example}
        alt="api documentation example"
        className="img-responsive"
      />
    </div>
  );
};

export const FeaturesCards1 = () => {
  return (
    <div className="">
      <div className="grid-container-f">
        <AnimatedCard bgClass="bg-black grid-item-f card-feature focus-xpirience">
          <h2 className="text-dark no-margin">Fast Integration with Existing Systems</h2>
        </AnimatedCard>
        <AnimatedCard bgClass="bg-black card-feature grid-item-f focus-xpirience">
        <h6 className="text-dark no-margin">
            Available in Stripe Marketplace for inmediate integration with your Stripe workflows.
            Scan your customers directly from the Stripe dashboard.
          </h6>
        </AnimatedCard>
        <AnimatedCard bgClass="bg-black card-feature grid-item-f focus-xpirience">
          <h4 className="text-dark no-margin">Easy implementation</h4>
          <div className="align-left">
            <h6 className="text-dark no-margin">
              Developer portal with extensive documentation and code samples.
            </h6>
            <h6 className="text-dark no-margin">
              RESTful API and Swagger 3.0 specification.
            </h6>

            <h6 className="text-dark no-margin">
              Manage your users and API-keys directly in the portal.
            </h6>
          </div>
        </AnimatedCard>
        <AnimatedCard bgClass="bg-black card-feature grid-item-f focus-xpirience">
        <DevPortal></DevPortal>
        </AnimatedCard>
      </div>
    </div>
  );
};

export const DevPortal = () => {

 
  
  return (
    <div
  className=" flex align-center dir-row justify-center  " >
      <img
        src={code_thinking}
        alt="Search Desktop"
        className="icon-feature side-padding3 b-padding1 t-padding1"/>

      <Button
        className="button-dark"
        href={con.urls.dev_portal}
        target="_blank"
        rel="noopener noreferrer"
        size="large"
      >
        Visit Developer´s Portal
      </Button>
    </div>
  );
};