import React from "react";

import AppLayoutLogin from "../components/AppLayoutLogin";
import { Button } from 'antd';

import Footer from "../components/Footer";
import con from "../config.json";
import contact_us from '../images/contact_us.webp'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
  
  } from "@fortawesome/free-solid-svg-icons";

const LandingPage = () => {
  return (
    <AppLayoutLogin>
     <div className="main-section dir-col align-center  " > 
     <h1 className='display-heading'>Contact us</h1> 
<div className="align-flex reverse max-width14 ">

    <div className="align-left dir-col  max-width7 side-padding2">
        
      
        <h4 className="b-margin2 t-margin2">How Can We Assist You? We're dedicated to supporting you at every stage.</h4> 
        
        <h6 className="b-margin2 t-margin2">
          <FontAwesomeIcon className="icon" icon={faCheckCircle} /><strong> Schedule a Demo:</strong> Experience our cutting-edge compliance tools in action.
        </h6>
        <h6 className="b-margin2">
          <FontAwesomeIcon className="icon" icon={faCheckCircle} /><strong> Request Support:</strong> Get personalized assistance and resolve your queries with our expert team.
        </h6>
        <h6 className="b-margin2">
          <FontAwesomeIcon className="icon" icon={faCheckCircle} /> <strong>Career Openings: </strong>Explore dynamic job opportunities and advance your career with us.
        </h6>
       
    
        <div className="buttons-row">
        

        <a href={con.calendly.url} target="_blank" rel="noopener noreferrer">
        <Button className='w240 extra-height1 text-button-big' type="primary" size="large"  
       
         >Schedule a call
                
        </Button>
        </a>
        <a
                  href={`mailto:${con.email.contact}?subject=Contact`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className='w240 extra-height1 ' type="default" size="large"  
       
       >contact@checklynx.com
              
      </Button>
                </a>
        
        
    </div>

   

    </div>
    <div className='max-width6 align-center side-padding2'>
    <img src={contact_us} alt="Search Desktop" className='img-responsive' />
    </div>
    </div>

</div>
       
      

      <Footer />
    </AppLayoutLogin>
  );
};

export default LandingPage;
