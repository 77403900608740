import React from "react";
import search_mobile_dark from "../images/search_mobile_dark.webp";
import { useInView } from 'react-intersection-observer';

const SearchExperience = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1,    // Adjust as needed
  });
  return (
    <div id="search-experience" className="main-section align-flex   bg-dark">
      <div className="align-flex max-width12">
      <h2 ref={ref} className={`display-heading b-padding2 side-padding3 align-center white ${inView ? 'animate' : ''}`}>
          Advance Search Performance
        </h2>


        <div className="align-flex justify-center" >

            <div ref={ref} className={` max-width4 align-center side-padding2 ${inView ? 'animate' : ''}`} >
              <img
                src={search_mobile_dark}
                alt="Search Desktop"
                className="img-responsive"
              />
            </div>
            <div className={`content justify-center ${inView ? 'animate' : ''}`} >
              <h2 className=" align-left large-text white">
                Find only relevant information and reduce false positives
              </h2>
              
              
              <h4 className="align-left white">
              We use state-of-the-art neural technology to automatically adapt to your search criteria and context.
              </h4>
              <h6 className="align-left white">
              Our proprietary algorithms enhance and classify data to not only optimize search efficiency but also significantly reduce false positives. 
          This leads to more accurate screening results, enabling you to trust the data you base your decisions on.
              </h6>
             
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchExperience;
