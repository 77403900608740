import React from "react";
import dot_map from "../images/dot_map.webp"; // Ensure the image is imported
import { CheckOutlined } from "@ant-design/icons"; // Import the icon from Ant Design
import { useInView } from "react-intersection-observer";

const Databases = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Adjust as needed
  });
  return (
    <div
      id="databases"
      ref={ref}
            className={`main-section dir-row align-flex max-width12 ${
              inView ? "animate" : ""
            }`}
     
    >
      <div className="content dir-row rounded-box-card">
        <h2 className="b-margin3 display-heading align-left">
          Global Sanctions and PEP Data
        </h2>
        <h5 className="align-left">
        Continues data updates using automatized processes and cutting-edge algorithms 

        </h5>
        <h6 className="align-left">
        Real time updates and identification of sanctioned individuals before official notifications.

        </h6>
        <h6 className="align-left">
        Support for your own local lists.
        </h6>
        <h6 className="align-left">
          
          Cover for all global sanctions and Politically Exposed
          Persons (PEP) lists, spanning regions including EMEA, the Americas,
          and APAC.
        </h6>
      </div>
      <div
        className="content-bg-img dir-col"
        style={{ "--image-url": `url(${dot_map})` }}
      >
        <div className="content--narrow ">
          <h2 className="important-list-text">
            
            <CheckOutlined className="icon"/> International Consolidated Lists: United Nations
            Sanctions (UN), World Bank...
          </h2>
          
            <h2 className="important-list-text">
            <CheckOutlined className="icon"/> EMEA: EU, UK (HMT), France, Netherlands, Switzerland,
            Israel...
            </h2>
            <h2 className="important-list-text">
            <CheckOutlined className="icon"/> Americas: US Consolidated Sanctions, OFAC SDN, Canada Financial Institution,
            Brazil, Argentina, Inter-American Development Bank...
            </h2>
            <h2 className="important-list-text">
            <CheckOutlined className="icon" /> Africa: South Africa, African Development Bank...
            </h2>
            <h2 className="important-list-text">
            <CheckOutlined className="icon"/> APAC: Australian Sanctions, Asian Dev. Bank,
            Japan...
            </h2>
        </div>
      </div>
    </div>
  );
};

export default Databases;
