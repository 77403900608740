import { useInView } from "react-intersection-observer";

export const AnimatedCard = ({ bgClass, children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust the threshold as needed for when you want the animation to start
  });
  return (
    <div
      ref={ref}
      className={`${bgClass}   flex bs-dir-row justify-center ${
        inView ? "animate" : ""
      }`}
    >
      <div className="flex  justify-center">{children}</div>
    </div>
  );
};



export const LogoCard = ({ externalLink, bgClass, iconSVG, heading, text }) => {
  

  const handleClick = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');  // Opens the link in a new tab
      // Alternatively, use window.location.href = externalLink; to open in the same tab
    }
  };

  return (
    <div onClick={handleClick}>
      <div
        
        className={`${bgClass} align-center `}
      >
       {iconSVG && <img src={iconSVG} alt="Logo" className='img-responsive logo-size' />}
        {heading && <h3 className="text-dark no-margin">{heading}</h3>}
        {text && <h6 className="text-dark no-margin">{text}</h6>}
      </div>
    </div>
  );
};