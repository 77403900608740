import React  from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import  {ThemeProvider}  from './contexts/ThemeContext';

import LandingPage from './pages/LandingPage';
import PrivacyNotice from './pages/PrivacyNotice';
import Terms from './pages/Terms';
import ContactUs from './pages/ContactUs';



// App Component
function App() {
  return (
   
       <ThemeProvider>
      <Router>
        <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<PrivacyNotice />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/pricing" element={<LandingPage scrollTo="pricing" />} />
        <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </Router>
      </ThemeProvider>
   
  );
}

export default App;