import React from "react";

import { Layout } from "antd";
import {  Button } from "antd";
import { useNavigate } from "react-router-dom";
import con from '../config.json';


const { Header, Content } = Layout;

const AppLayoutLogin = ({ children }) => {
  const navigate = useNavigate();
  

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleLogInClick = () => {
    window.location.href = con.urls.checklynx_app+"/login"
  };
  
  const handleSignUpClick = () => {
    window.location.href = con.urls.checklynx_app_sign_up
  };


  return (
    
    <Layout style={layoutStyle}>
    <Header className="flex justify-space-between header-container side-padding3-1">
      <div
        onClick={handleLogoClick}
        cursor="pointer"
        style={{ cursor: "pointer" }}
        className="header-title "
      >
        Checklynx
      </div>

         
      <div className="flex dir-row  gap1">
          <Button
            type="primary"
            ghost={true}
            onClick={handleLogInClick}
            size="large"
          >
            Log in
          </Button>
         
         
          <Button
            className=" hide-small-screen "
            type="primary"
            size="large"
            onClick={handleSignUpClick}
          >
            Create account
          </Button>
        </div>
      </Header>
      <Content style={contentStyle}>{children}</Content>
    </Layout>
  );
};

export default AppLayoutLogin;

const layoutStyle = {
  padding: "0rem", // Padding as needed
  width: "100%",
  maxWidth: "100%",
  display: "flex",
  height: `calc(100vh)`, // Subtract header height from total view height
  flexDirection: "column",
  backgroundColor: "white", 
 
};

const contentStyle = {
  overflowY: "auto", // Allow scrolling
  overflowX: "hidden",
  alignItems: "center", // Center children vertically
  flexDirection: "column",
  display: "flex",
};
